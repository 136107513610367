#cssmenu ul,
#cssmenu li,
#cssmenu span,
#cssmenu a {
  margin: 0;
  padding: 0;
  position: relative;
}

#cssmenu {
  line-height: 1;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  border-bottom: 0px solid #db000b;
}
#cssmenu:after,
#cssmenu ul:after {
  content: '';
  display: block;
  clear: both;
}
#cssmenu a {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  color: #ffffff;
  display: block;
  font-family: Helvetica, Arial, Verdana, sans-serif;
  padding: 12px 20px;
  text-decoration: none;
}
#cssmenu ul {
  list-style: none;
}
#cssmenu > ul > li {
  display: inline-block;
  float: left;
  margin: 0;
}
#cssmenu.align-center {
  text-align: center;
}
#cssmenu.align-center > ul > li {
  float: none;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul {
  float: right;
}
#cssmenu.align-right ul ul {
  text-align: right;
}
#cssmenu > ul > li > a {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}
#cssmenu > ul > li:hover:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  margin-left: -10px;
}
#cssmenu > ul > li:first-child > a {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
#cssmenu.align-right > ul > li:first-child > a,
#cssmenu.align-center > ul > li:first-child > a {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
#cssmenu.align-right > ul > li:last-child > a {
  border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
}
#cssmenu > ul > li.active > a,
#cssmenu > ul > li:hover > a {
  color: #000000;
  box-shadow: inset 0 0 3px #d9d9d9;
  -moz-box-shadow: inset 0 0 3px #d9d9d9;
  -webkit-box-shadow: inset 0 0 3px #d9d9d9;
  background: #f2f2f2;
  background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f2f2f2));
  background: -webkit-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
}
#cssmenu .has-sub {
  z-index: 1;
}
#cssmenu .has-sub:hover > ul {
  display: block;
}
#cssmenu .has-sub ul {
  display: none;
  position: absolute;
  width: 210px;
  top: 100%;
  left: 0;
}
#cssmenu.align-right .has-sub ul {
  left: auto;
  right: 0;
}
#cssmenu .has-sub ul li {
  *margin-bottom: -1px;
}
#cssmenu .has-sub ul li a {
  background: #004578;
  border-bottom: 1px dotted #185e98;
  font-size: 12px;
	font-weight: 600;
  filter: none;
  display: block;
  line-height: 120%;
  padding: 10px;
  color: #ebebeb;
}
#cssmenu .has-sub ul li:hover a {
  background: #185e98;
}
#cssmenu ul ul li:hover > a {
  color: #ebebeb;
}
#cssmenu .has-sub .has-sub:hover > ul {
  display: block;
}
#cssmenu .has-sub .has-sub ul {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
}
#cssmenu.align-right .has-sub .has-sub ul,
#cssmenu.align-right ul ul ul {
  left: auto;
  right: 100%;
}
#cssmenu .has-sub .has-sub ul li a {
  background: #c41a30;
  border-bottom: 1px dotted #ff0f1b;
}
#cssmenu .has-sub .has-sub ul li a:hover {
  background: #c41a30;
}
#cssmenu ul ul li.last > a,
#cssmenu ul ul li:last-child > a,
#cssmenu ul ul ul li.last > a,
#cssmenu ul ul ul li:last-child > a,
#cssmenu .has-sub ul li:last-child > a,
#cssmenu .has-sub ul li.last > a {
  border-bottom: 0;
}
h5, h6 {
  margin: 10px 0;
  font-family: inherit;
  font-weight: bold;
  line-height: 20px;
  color: inherit;
  text-rendering: optimizelegibility;
}
.mycarousel-caption {
	background: #bd1621;
	width: 420px;
	text-align: center;
	margin: 15% 40%;
}
.link_button2 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #ebebeb;
  color: #000;
  text-decoration: none;
  padding: 6px 12px;
  text-decoration: none;
  font-size: 14px;
}
#middleSection .well a span {font-size: 13px; margin: 0 -18px;}
a.link_button2:hover {
  background: #0d5cb9;
  color: #fff;
}
.well {
  min-height: 20px;
  margin-bottom: 10px;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}
well-small {
  padding: 9px;
  border-radius: 3px;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
}
h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-size: 15px;
  color: #000;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.thumbnail h4, #middleSection h4 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.media-list {
  margin-left: 0;
  list-style: none;
}
#bannerSection h1 {
  color: #fff;
  padding: 0;
  height: 220px;
  text-align: center;
  font-weight: bold;
}
h3 {
  font-size: 24.5px;
  text-align: left;
}
p {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
 }
.mytable{
	width:100%;
	height:100%;
	margin:0px;
  padding:2px;
  background-color:#ffffff; 
  border: #000000 1px solid;
}
.mytable thead th {
  background-color: #3564B1;
  color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}
.mytable tr:hover td{
	background-color:#eaf8ff;
}
.mytable tr:nth-child(odd){ 
  background-color:#f3f3f3;
}
.mytable tr:nth-child(even)    { 
  background-color:#ffffff; 
}
.mytable td{
	vertical-align:middle;
	border: #cccccc 1px solid;
	text-align:left;
	padding:7px;
  font-size:14px;
	font-family:Arial;
	font-weight:normal;
	color:#000000;
}
.jobdet {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #333333;
  text-align: left;
  border: 1px solid #ccc;
  padding: 15px;
}
.content_bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #333333;
  text-align: left;
}
.container .contact-form {
  width: 100%;
}
.container .contact-form input, .container .contact-form textarea {
  width: 80%;
  padding: 1%;
  margin: 2% 8%;
  color: #242424;
  border: 1px solid #B7B7B7;
}
.container .contact-form textarea {
  width: 80%;
  padding: 1%;
  margin: 2% 8%;
  color: #242424;
  border: 1px solid #B7B7B7;
}
.container .contact-form .btn-send {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #0d5cb9;
  color: #fff;
  text-decoration: none;
  margin: 0 30%;
  padding: 6px 10%;
  text-decoration: none;
  font-size: 14px;
}
.container .login-form .select,.input {
  display: inline-block;
  height: 20px;
  padding: 4px 6px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #555;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.container .login-form .btn-primary {
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
  background-color: #006dcc;
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  border-color: #04c #04c #002a80;
  color: #ffffff;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}
.img-circle {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}
.contentbox {
  width: 95%;
  border: 1px solid #337ab7;
}
.contentheading {
  background: #337ab7;
  padding: 10px;
  color: #fff;
  height: 40px;
}
.ui_text{
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 30px;
  width: 220px;
}
.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.breadcrumbs{
	font-size:12px;
	background-color:#e7f0fe;
	padding:5px;
}
.breadcrumbs a{font-size:12px;color:#055cdf;text-decoration: none;}
.concontentbox {
  width: 810px;
  margin: auto;
  border: 1px solid #337ab7;
}
.concontentbody {
  background: #fff;
  min-height: 550px;
  padding: 20px;
}
.concontentheading {
  background: #004578;
  padding: 10px;
  padding-left: 15px;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.profilebox {
  padding: 44px;
  font-size: 13px;
  font-weight: bold;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.contentbox {
  width: 55%;
  margin: auto;
  border: 1px solid #337ab7;
}

.contentheading {
  background: #004578;
  padding: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  height: fit-content;
}

.contentbody {
  background: #fff;
  padding: 50px;
}







